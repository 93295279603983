import Swiper, {Pagination, Thumbs, Navigation, EffectFade, Manipulation, Autoplay} from 'swiper';

class Helpers {

    constructor() {
        $(document).ready(this.ready.bind(this));
    }

    /**
     * Method called after document ready.
     */
    ready() {
        this.bind();
    }

    /**
     * Bind events
     */
    bind() {

    }

    ajaxRequest = (params = {}) => {
        const defaultParams = {
            url: ajaxObject.ajaxurl,
            type: 'POST',
            data: {
                security: ajaxObject.security
            }
        }

        return $.ajax(this.deepMergeObject(defaultParams, params));
    }

    deepMergeObject = (targetObject = {}, sourceObject = {}) => {
        // clone the source and target objects to avoid the mutation
        const copyTargetObject = JSON.parse(JSON.stringify(targetObject));
        const copySourceObject = JSON.parse(JSON.stringify(sourceObject));
        // Iterating through all the keys of source object
        Object.keys(copySourceObject).forEach((key) => {
            if (typeof copySourceObject[key] === "object" && !Array.isArray(copySourceObject[key])) {
                // If property has nested object, call the function recursively
                copyTargetObject[key] = this.deepMergeObject(
                    copyTargetObject[key],
                    copySourceObject[key]
                );
            } else {
                // else merge the object source to target
                copyTargetObject[key] = copySourceObject[key];
            }
        });

        return copyTargetObject;
    }

    /**
     * Check if is in viewport
     * @param element
     * @returns {boolean}
     */
    isInViewport = (element) => {

        if (0 === $(element).length) {
            return false;
        }

        const element_top = $(element).offset().top;
        const element_bottom = element_top + $(element).outerHeight();
        const viewport_top = $(window).scrollTop();
        const viewport_bottom = viewport_top + $(window).height();

        return element_bottom > viewport_top && element_top < viewport_bottom;
    }

    /**
     * Return a swiper with some default options ready
     * @param element
     * @param params
     * @returns {Swiper}
     */
    swiper = (element, params = {}) => {

        // bail if no selectors
        if ($(`${element}`).length === 0) {
            return null;
        }

        let options = {
            modules: [
                Pagination,
                Thumbs,
                Navigation,
                EffectFade,
                Manipulation,
                Autoplay
            ],
            loop: true,
            speed: 1200,
            preloadImages: false,
            lazy: {
                elementClass: 'lazy',
                loadOnTransitionStart: true,
            },
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            multipleActiveThumbs: false,
        }

        options = {...options, ...params};

        return new Swiper(element, options);
    }
}

export default Helpers;


