import Helpers from './modules/helpers';

/**
 * General theme js class
 */
class Theme {

    giftsSwiperClass = '.gifts-homepage-swiper';
    productsSwiperClass = '.products-homepage-swiper';
    personalizedGiftsSwiperClass = '.personalized-gifts-homepage-swiper';
    testimonialsSwiperClass = '.testimonials-homepage-swiper';
    megaMenuDropDownMenuColumnClass = '.drop-down-menu-column';
    megaMenuDropDownSubMenuColumnClass = '.drop-down-sub-menu-column';
    megaMenuLinkClass = '.mega-menu-link';
    mobileSubMenuToggler = '.menu-item-has-children > a';
    isEventHandled = false;

    constructor() {
        this.Helpers = new Helpers();
        $(document).ready(this.ready.bind(this));
        $(window).on('load', this.load.bind(this));
    }

    /**
     * Method called after document ready.
     */
    ready() {
        this.bind();
    }

    /**
     * Method called after window loaded.
     */
    load() {
    }

    bind() {
        this.renderGiftSlider();
        this.renderProductsSlider();
        this.renderPersonalizedGiftsSlider();
        this.renderTestimonialsSlider();
        this.megaMenu();

        $(this.mobileSubMenuToggler).on('click touchstart', this.toggleAllProductsSubMenu);

        // Disable the submit button when the document is ready
        $('.check-validity-form input[type="submit"]').prop('disabled', true);

        // check validity for these forms
        $('.check-validity-form').on('change keyup', this.checkFormValidity);
        document.addEventListener('wpcf7mailsent', this.handleFormSubmission, false);

        $('#newsletter-form-submit').closest('form').submit(this.newsletterSubmit);
    }

    /**
     * Renders a homepage gift slider
     */
    renderGiftSlider = () => {
        this.Helpers.swiper(this.giftsSwiperClass, {
            preventClicks: true,
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                575: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            },
            navigation: {
                nextEl: '.gifts-homepage-swiper-navigation .homepage-swiper-button-next',
                prevEl: '.gifts-homepage-swiper-navigation .homepage-swiper-button-prev',
            },
        });
    }

    /**
     * Renders a homepage products slider
     */
    renderProductsSlider = () => {
        this.Helpers.swiper(this.productsSwiperClass, {
            preventClicks: true,
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                575: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 32
                }
            },
            navigation: {
                nextEl: '.products-homepage-swiper-navigation .homepage-swiper-button-next',
                prevEl: '.products-homepage-swiper-navigation .homepage-swiper-button-prev',
            },
        });
    }

    /**
     * Renders a homepage personalized gifts slider
     */
    renderPersonalizedGiftsSlider = () => {
        this.Helpers.swiper(this.personalizedGiftsSwiperClass, {
            preventClicks: true,
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                575: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 12
                }
            },
            pagination: {
                el: '.personalized-gifts-homepage-swiper-pagination',
                clickable: true,
            },
        });
    }

    /**
     * Renders a homepage testimonials slider
     */
    renderTestimonialsSlider = () => {
        const testimonialsSlider = this.Helpers.swiper(this.testimonialsSwiperClass, {
            autoplay: {
                delay: 5000
            },
            slidesPerView: 3,
            spaceBetween: 16,
            centeredSlides: true,
            pagination: {
                el: '.testimonials-homepage-swiper-pagination',
                clickable: true,
            },
        });

        if (testimonialsSlider) {
            testimonialsSlider.on('transitionEnd', () => {
                this.updateSwiperHeight(testimonialsSlider);
            });
            testimonialsSlider.on('transitionStart', () => {
                this.updateSwiperHeight(testimonialsSlider);
            });
        }

    }

    /**
     * Update the swiper-wrapper height
     *
     * @param swiper
     */
    updateSwiperHeight = (swiper) => {
        const slides = swiper.slides;
        let maxHeight = 0;

        slides.forEach((slide) => {
            const slideHeight = slide.offsetHeight;

            // slide height is greater than max height
            if (slideHeight > maxHeight) {
                maxHeight = slideHeight;
            }
        });

        // add style
        swiper.$wrapperEl[0].style.height = `${maxHeight}px`;
    }

    /**
     * Sets up the hover functionality for the mega menu.
     */
    megaMenu() {
        let activeSubMenu = null;
        let timer;
        const dropDownMenuColumnItemClass = `${this.megaMenuDropDownMenuColumnClass} .menu-item`;
        const $dropDownMenuColumnItems = $(dropDownMenuColumnItemClass);
        const $dropDownSubMenuColumn = $(`${this.megaMenuDropDownSubMenuColumnClass}`);

        // show the sub-menu
        const showSubMenu = (element) => {
            const subMenu = $(element).find('.sub-menu').clone();
            $dropDownSubMenuColumn.empty().append(subMenu).addClass('active');
            activeSubMenu = subMenu;
        };

        // hide the sub-menu
        const hideSubMenu = () => {
            $dropDownSubMenuColumn.empty().removeClass('active');
            activeSubMenu = null;
        };

        /**
         * Mouse enter method
         *
         * @param event
         */
        const handleMouseEnter = (event) => {

            // clear timeout
            clearTimeout(timer);

            const $target = $(event.currentTarget); // maybe parent

            // remove other items active class
            $dropDownMenuColumnItems.removeClass('active');

            if ($target.hasClass('mega-menu-link')) {
                $dropDownMenuColumnItems.first().addClass('active');
                showSubMenu($dropDownMenuColumnItems.first());
            } else {
                $target.addClass('active');
                showSubMenu($target);
            }
        }

        /**
         * Mouse leave method
         *
         * @param event
         */
        const handleMouseLeave = (event) => {

            // set a small timeout
            timer = setTimeout(() => {

                // check for related target
                if (!$(event.relatedTarget).closest(this.megaMenuDropDownSubMenuColumnClass).length) {

                    // hide submenu
                    hideSubMenu();

                    // remove other items active class
                    $dropDownMenuColumnItems.removeClass('active');
                }
            }, 50);
        }

        // on main mega menu hover
        $(this.megaMenuLinkClass).on('mouseenter', handleMouseEnter).on('mouseleave', handleMouseLeave);

        // on submenu hover
        $dropDownMenuColumnItems.on('mouseenter', handleMouseEnter).on('mouseleave', handleMouseLeave);

        // cub-menu column hover
        $dropDownSubMenuColumn.mouseenter(function () {
            clearTimeout(timer);
            if (activeSubMenu) {
                $(this).addClass('active');
            }
        });

        // sub-menu column leave
        $dropDownSubMenuColumn.mouseleave(function (event) {
            timer = setTimeout(() => {
                if (!$(event.relatedTarget).closest(dropDownMenuColumnItemClass).length) {
                    hideSubMenu();
                    $dropDownMenuColumnItems.removeClass('active');
                }
            }, 50);
        });
    }

    /**
     * Gets coordinates of current target
     * @param e
     * @returns {{clickX: number, clickY: number, $anchor: (*|jQuery|HTMLElement)}}
     */
    getCoordinates = (e) => {
        const $anchor = $(e.currentTarget);
        const offset = $anchor.offset();
        let clickX, clickY;

        if (e.type === 'click') {
            clickX = e.pageX - offset.left;
            clickY = e.pageY - offset.top;
        } else if (e.type === 'pointerdown' || e.type === 'touchstart') {
            const event = e.type === 'pointerdown' ? e : e.originalEvent.touches[0];
            clickX = event.pageX - offset.left;
            clickY = event.pageY - offset.top;
        }

        return {clickX, clickY, $anchor};
    }

    /**
     * Toggles mobile menu children (sub-menus)
     */
    toggleAllProductsSubMenu = (e) => {
        if (this.isEventHandled) {
            return;
        }

        this.isEventHandled = true;

        const {clickX, clickY, $anchor} = this.getCoordinates(e);
        const width = $anchor.outerWidth();
        const height = $anchor.outerHeight();
        const pseudoElementWidth = 40;

        if (clickX > width - pseudoElementWidth && clickY > 0 && clickY < height) {
            e.preventDefault();
            e.stopPropagation();
            const $subMenu = $anchor.siblings('.sub-menu');
            if ($subMenu.is(':visible')) {
                $subMenu.slideUp();
                $anchor.parent().removeClass('mobile-active');
            } else {
                $subMenu.slideDown();
                $anchor.parent().addClass('mobile-active');
            }
        }

        // Reset the flag after a short delay to allow for next interaction
        setTimeout(() => {
            this.isEventHandled = false;
        }, 300);
    }

    /**
     * Check CF7 form validity and enable/disable submit button
     * @param event
     */
    checkFormValidity = (event) => {
        const $form = $(event.target).closest('form');

        // bail early
        if (!$form) {
            return;
        }

        const submitButton = $form.find('input[type="submit"]');
        const requiredFields = $form.find('[aria-required="true"]');
        const acceptanceField = $form.find('.wpcf7-acceptance input[type="checkbox"]');

        let isValid = true;

        // check if all required fields, including the Acceptance field, are filled out
        requiredFields.each(function () {
            const field = $(this);
            const fieldValue = field.val();
            const isInvalid = field.attr('aria-invalid') === 'true';

            // check if the field is empty or invalid
            if (fieldValue === '' || isInvalid) {
                isValid = false;
                return false;
            }
        });

        // check if the Acceptance field is checked
        if (!acceptanceField.prop('checked')) {
            isValid = false;
        }

        submitButton.prop('disabled', !isValid);
    }

    /**
     * Checks form response and if there is a file it will download
     *
     * @param event
     */
    handleFormSubmission = (event) => {
        const formElement = event.target;

        // check if is the catalog form
        if (!formElement.classList.contains('catalog-form')) {
            return;
        }

        const fileUrl = event.detail.apiResponse.file_url;

        // if there is a file to download
        if (fileUrl) {
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            a.click();
            document.body.removeChild(a);
        }
    }

    newsletterSubmit = (e) => {
        e.preventDefault();

        const form = $(e.target);
        const formData = form.serialize();

        this.Helpers.ajaxRequest({
            data: {
                action: 'process_mailchimp_subscription',
                form_data: formData
            },
        }).done((response) => {
            const responseDiv = form.find('.mc4wp-response');

            // clear html
            responseDiv.empty();

            const message = response.data && response.data.message ? response.data.message : 'There was an error processing your request.';
            responseDiv.append(`<div class="mc4wp-alert mc4wp-notice" role="alert"><p>${message}</p></div>`);

            // reset form on success
            if (response.success) {
                form[0].reset();
            }

        }).fail((jqXHR, textStatus, errorThrown) => {
            console.error(errorThrown);
        });
    }

}

export default new Theme();
